import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

import { Footer } from  '../components/Footer';

const ContainerStyle = { 
  display: 'flex', 
  flexDirection: 'column',
  justifyContent: 'center', 
  alignItems: 'center'
};

const LogoStyle = {
	fontFamily: '"Darker Grotesque", sans-serif',
	fontWeight: 600, // SemiBold
	userSelect: 'none', // Disable text selection
	cursor: 'default', // Change cursor to default
	fontSize: {
    xs: '5rem',  // for extra-small screens
    sm: '8rem',  // for small screens
    md: '12rem', // for medium screens
    lg: '15rem'  // for large screens and up
  },
  textAlign: 'center',
  wordBreak: 'break-word'
};

export const Home: React.FunctionComponent = () => (
  <Container sx={ContainerStyle}>
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Typography 
        variant={'h1'} 
        sx={{
          ...LogoStyle,
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Anyways
      </Typography>
    </Box>
    <Box sx={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', backgroundColor: 'black'}}>
      <Typography paddingLeft={"1em"} variant="h4" component="h1" gutterBottom>
        Level Up
      </Typography>
      <List sx={{ padding: '1rem' }}>
        {[
          'Custom streaming tools',
          'Real-time analytics',
          'Targeted brand partnerships',
        ].map((text, index) => (
          <ListItem key={index}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
    <Footer/>
  </Container>
);

export default Home;
