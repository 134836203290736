import React from 'react';
import { Box } from '@mui/material';

const BoxStyle = {
    height: '6em', 
    width: '100vw', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#111'
}

export const Footer: React.FunctionComponent = () => (
	<Box sx={BoxStyle}>
        <h4>©2024 Anyways - <a style={{color: 'white'}} href='mailto:contact@anyways.tv'>contact@anyways.tv</a></h4>
    </Box>
);
