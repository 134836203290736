import React from 'react';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import type { Theme, Components, LinkProps } from '@mui/material';

export const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
	const { href, ...other } = props;
	return <RouterLink ref={ref} to={href} {...other} />;
});

export const ComponentThemes: Components<Omit<Theme, "components">> = {
	MuiLink: {
		defaultProps: {
			component: LinkBehavior,
		} as LinkProps,
	},
	MuiButtonBase: {
		defaultProps: {
			LinkComponent: LinkBehavior,
		},
	},
};
